"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { addOnWaitList } from "@/actions/waitlist";
import Alert, { AlertType } from "@/components/Alerts";
import { IWaitListFormInput, WaitListFormSchema } from "@/lib/form/schema";

export type WaitListFormProps = {
  buttonTitle?: string;
};

const WaitListForm: React.FC<WaitListFormProps> = ({ buttonTitle = "Submit" }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IWaitListFormInput>({
    resolver: zodResolver(WaitListFormSchema),
  });

  const onSubmit: SubmitHandler<IWaitListFormInput> = async (data) => {
    setIsSubmitting(true);
    try {
      const result = await addOnWaitList(data);
      if (!result) {
        // Sentry error
        toast.custom(<Alert type={AlertType.Error} content="Something went wrong" />);
        return;
      }

      if (result.error) {
        // @ts-ignore
        const error = result.error?._errors ? result.error?._errors?.join(", ") : result.error?.message;
        toast.custom(<Alert type={AlertType.Error} content={error} />);
        return;
      }

      reset();
      toast.custom(<Alert type={AlertType.Success} content="Email has been added to the waiting list" />);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8 sm:mt-10">
      <div className="group relative p-2 sm:rounded-xl sm:border sm:border-gray-400 sm:focus-within:ring-1">
        <input
          {...register("email")}
          type="email"
          placeholder="Enter email address"
          className="block w-full rounded-xl border border-gray-400 bg-transparent p-4 outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500 sm:border-none sm:focus:border-transparent sm:focus:ring-0"
        />
        <div className="mt-4 sm:absolute sm:inset-y-0 sm:right-0 sm:mt-0 sm:flex sm:items-center sm:pr-2">
          <button
            type="submit"
            disabled={isSubmitting}
            className="font-pj inline-flex w-full flex-row items-center justify-center rounded-lg bg-gray-500 px-6 py-3 text-center text-lg font-bold text-white transition-all duration-200 hover:bg-gray-600 focus:bg-gray-600 focus:outline-none"
          >
            {isSubmitting && <span className="loading loading-xs mr-4" />}
            {buttonTitle}
          </button>
        </div>
      </div>
      {errors.email && <p className="ml-2 mt-2 text-center text-error sm:text-left">{errors.email.message}</p>}
    </form>
  );
};

export default WaitListForm;
