import { Html } from "@react-three/drei";

const Loader = () => {
  return (
    <Html>
      <div className="flex h-full w-full">
        <div className="skeleton rounded-xl" />
      </div>
    </Html>
  );
};

export default Loader;
