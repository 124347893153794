import { z } from "zod";

export const WaitListFormSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
});

export type IWaitListFormInput = z.infer<typeof WaitListFormSchema>;

export const OAuthFormSchema = z.object({
  provider: z.string().min(1, { message: "Provider name is required" }),
  callbackUrl: z.string().optional(),
});

export type IOAuthFormInput = z.infer<typeof OAuthFormSchema>;
